import React from 'react';

function Progress({ progress }) {
  const { totalAnswered, correctAnswers } = progress;
  const percentage = totalAnswered > 0 ? (correctAnswers / totalAnswered) * 100 : 0;

  return (
    <div>
      <h2>Your Progress</h2>
      {totalAnswered > 0 ? (
        <div>
          <p>You have answered {totalAnswered} questions.</p>
          <p>Correct answers: {correctAnswers}</p>
          <div style={{ width: '100%', backgroundColor: '#e0e0e0', borderRadius: '10px' }}>
            <div
              style={{
                width: `${percentage}%`,
                backgroundColor: '#277319',
                height: '24px',
                borderRadius: '10px',
                textAlign: 'center',
                color: 'white',
                fontWeight: 'bold',
              }}
            >
              {percentage.toFixed(2)}%
            </div>
          </div>
        </div>
      ) : (
        <p>You haven't answered any questions yet.</p>
      )}
    </div>
  );
}

export default Progress;
