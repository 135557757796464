import React from 'react';
import ReactDOM from 'react-dom/client'; // React 18 import
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Initialize Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

// Create a root.
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render your app inside the root.
root.render(
  <React.StrictMode>
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </React.StrictMode>
);

// Measuring performance
reportWebVitals();
