// src/components/Success.js
import React from 'react';

const Success = () => (
  <div>
    <h2>Payment Successful!</h2>
    <p>Thank you for your purchase. Your access will be granted shortly.</p>
  </div>
);

export default Success;
