import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import './Question.css';

function Question({ progress, updateProgress }) {
  const [questions, setQuestions] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [feedback, setFeedback] = useState({});
  const [error, setError] = useState('');
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'questions'));
        const questionList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setQuestions(questionList);

        // Set initial selected answers and feedback
        const initialSelectedAnswers = {};
        const initialFeedback = {};

        questionList.forEach((question) => {
          const answerData = progress.answeredQuestions[question.id];
          if (answerData) {
            initialSelectedAnswers[question.id] = answerData.selectedAnswer;
            initialFeedback[question.id] = answerData.isCorrect
              ? `Correct! ${question.explanation}`
              : `Incorrect. The correct answer is ${question.correctAnswer}. ${question.explanation}`;
          }
        });

        setSelectedAnswers(initialSelectedAnswers);
        setFeedback(initialFeedback);
      } catch (err) {
        setError('Failed to load questions');
        console.error(err);
      }
    };

    fetchQuestions();
  }, [progress]);

  const handleAnswerSelect = (questionId, option) => {
    setSelectedAnswers((prevState) => ({
      ...prevState,
      [questionId]: option,
    }));
  };

  const handleSubmit = (questionId) => {
    const selectedAnswer = selectedAnswers[questionId];
    const question = questions.find((q) => q.id === questionId);

    if (!selectedAnswer) {
      setFeedback((prevState) => ({
        ...prevState,
        [questionId]: 'Please select an answer.',
      }));
      return;
    }

    const isCorrect = selectedAnswer === question.correctAnswer;

    setFeedback((prevState) => ({
      ...prevState,
      [questionId]: isCorrect
        ? `Correct! ${question.explanation}`
        : `Incorrect. The correct answer is ${question.correctAnswer}. ${question.explanation}`,
    }));

    updateProgress(questionId, selectedAnswer, isCorrect);
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      alert('You have completed all questions.');
    }
  };

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <div className="question-page">
      <div className="questions-section">
        {error && <p>{error}</p>}
        {currentQuestion && (
          <div key={currentQuestion.id} className="question-container">
            <h3>{currentQuestion.question}</h3>
            {currentQuestion.options.map((option, index) => (
              <div key={index}>
                <input
                  type="radio"
                  id={`${currentQuestion.id}-${option}`}
                  name={currentQuestion.id}
                  value={option}
                  onChange={() => handleAnswerSelect(currentQuestion.id, option)}
                  checked={selectedAnswers[currentQuestion.id] === option}
                  disabled={!!progress.answeredQuestions[currentQuestion.id]}
                />
                <label htmlFor={`${currentQuestion.id}-${option}`}>{option}</label>
              </div>
            ))}
            <button
              onClick={() => handleSubmit(currentQuestion.id)}
              disabled={
                !!progress.answeredQuestions[currentQuestion.id] || feedback[currentQuestion.id]
              }
            >
              Submit Answer
            </button>
            {feedback[currentQuestion.id] && <p>{feedback[currentQuestion.id]}</p>}
            <button
              onClick={handleNextQuestion}
              disabled={!feedback[currentQuestion.id]}
            >
              Next Question
            </button>
          </div>
        )}
      </div>
      <div className="progress-tracker">
        <h3>
          Question {currentQuestionIndex + 1} of {questions.length}
        </h3>
        <h3>
          Score: {((progress.correctAnswers / progress.totalAnswered) * 100 || 0).toFixed(2)}%
        </h3>
      </div>
    </div>
  );
}

export default Question;
