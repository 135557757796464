import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from '../firebase';
import './Navbar.css'; // Import the new CSS file

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [user, setUser] = useState(null); // State to track if user is logged in
  const navigate = useNavigate(); // useNavigate hook

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe(); // Cleanup the listener
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Function to handle navigation on link clicks
  const handleClick = (path) => {
    navigate(path);
    setMenuOpen(false); // Close the mobile menu after navigation
  };

  const handleLogout = () => {
    signOut(auth).then(() => {
      navigate('/'); // Navigate to home after logging out
    });
  };

  return (
    <div className="bar">
      <div className="navbar">
        <div className="logo" onClick={() => handleClick('/')}>MasterMLA</div>
        <div className={`links-container ${menuOpen ? 'open' : ''}`}>
          {user ? (
            <button className="send-nuqoot-btn" onClick={handleLogout}>Log Out</button>
          ) : (
            <>
              <button className="send-nuqoot-btn" onClick={() => handleClick('/login')}>Login</button>
              <button className="send-nuqoot-btn" onClick={() => handleClick('/signup')}>Sign Up</button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
