// src/components/Pages/QuestionsPage.js

import React, { useState, useEffect } from 'react';
import { auth, db } from '../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { collection, addDoc, query, where, getDocs, updateDoc, doc, getDoc } from 'firebase/firestore';
import { useNavigate, useLocation } from 'react-router-dom';
import Question from '../Question';
import './QuestionsPage.css';

const QuestionsPage = () => {
  const [user, setUser] = useState(null);
  const [hasAccess, setHasAccess] = useState(false); // New state variable for access control
  const [progress, setProgress] = useState({
    totalAnswered: 0,
    correctAnswers: 0,
    answeredQuestions: {},
  });
  const [questions, setQuestions] = useState([]);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const selectedCategories = location.state?.selectedCategories || [];

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);

        // Access control logic
        try {
          // Fetch the user's access information from Firestore
          const userDocRef = doc(db, 'users', currentUser.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const data = userDoc.data();
            const now = new Date();
            if (data.accessExpiration && data.accessExpiration.toDate() > now) {
              setHasAccess(true);
            } else {
              setHasAccess(false);
              navigate('/checkout'); // Redirect to checkout if access has expired
            }
          } else {
            // User document doesn't exist, redirect to checkout
            setHasAccess(false);
            navigate('/checkout');
          }
        } catch (err) {
          console.error('Error fetching user access data:', err);
          setError('Failed to verify access permissions.');
          setHasAccess(false);
          navigate('/checkout');
        }
      } else {
        navigate('/login'); // Redirect to login if not authenticated
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        let questionList = [];
        if (selectedCategories.length > 0) {
          const batchSize = 10;
          for (let i = 0; i < selectedCategories.length; i += batchSize) {
            const batchCategories = selectedCategories.slice(i, i + batchSize);
            const q = query(collection(db, 'questions'), where('category', 'in', batchCategories));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(doc => {
              questionList.push({
                id: doc.id,
                ...doc.data(),
              });
            });
          }
        } else {
          // No categories selected, do not fetch any questions
          questionList = [];
        }
        setQuestions(questionList);
      } catch (err) {
        setError('Failed to load questions');
        console.error(err);
      }
    };

    if (hasAccess) {
      fetchQuestions();
    }
  }, [selectedCategories, hasAccess]);

  const updateProgress = (questionId, selectedAnswer, isCorrect) => {
    setProgress((prevProgress) => {
      const newProgress = {
        totalAnswered: prevProgress.totalAnswered + 1,
        correctAnswers: prevProgress.correctAnswers + (isCorrect ? 1 : 0),
        answeredQuestions: {
          ...prevProgress.answeredQuestions,
          [questionId]: { selectedAnswer, isCorrect },
        },
      };

      if (user) {
        saveUserProgress(user.uid, questionId, selectedAnswer, isCorrect);
      }

      return newProgress;
    });
  };

  const saveUserProgress = async (userId, questionId, selectedAnswer, isCorrect) => {
    const userProgressRef = collection(db, 'userProgress');
    const q = query(
      userProgressRef,
      where('userId', '==', userId),
      where('questionId', '==', questionId)
    );
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      await addDoc(userProgressRef, {
        userId: userId,
        questionId: questionId,
        selectedAnswer: selectedAnswer,
        isCorrect: isCorrect,
        timestamp: new Date(),
      });
    } else {
      const docId = querySnapshot.docs[0].id;
      const docRef = doc(db, 'userProgress', docId);
      await updateDoc(docRef, {
        selectedAnswer: selectedAnswer,
        isCorrect: isCorrect,
        timestamp: new Date(),
      });
    }
  };

  return (
    <div className="questions-container">
      {user && hasAccess ? (
        <div className="question-section">
          {questions.length > 0 ? (
            <Question progress={progress} updateProgress={updateProgress} questions={questions} />
          ) : (
            <p>No questions available for the selected categories.</p>
          )}
        </div>
      ) : (
        <div>
          <p>You do not have access to the question bank.</p>
          <button onClick={() => navigate('/checkout')}>Purchase Access</button>
        </div>
      )}
      {error && <p className="error-text">{error}</p>}
    </div>
  );
};

export default QuestionsPage;
