// src/components/Cancel.js
import React from 'react';

const Cancel = () => (
  <div>
    <h2>Payment Cancelled</h2>
    <p>Your payment was cancelled. Please try again if you wish to purchase access.</p>
  </div>
);

export default Cancel;
