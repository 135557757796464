import React, { useState, useEffect } from 'react';
import { auth, db } from '../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { collection, query, where, getDocs, getCountFromServer } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './ProfilePage.css';
import Progress from '../Progress';
import Footer from '../Footer';

const ProfilePage = () => {
  const [user, setUser] = useState(null);
  const [progress, setProgress] = useState({
    totalAnswered: 0,
    correctAnswers: 0,
    answeredQuestions: {},
  });
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [categories, setCategories] = useState([
    { name: 'Cardiology', total: 0, attempted: 0 },
    { name: 'Respiratory', total: 0, attempted: 0 },
    { name: 'Gastroenterology', total: 0, attempted: 0 },
    // ... other categories
  ]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        const hasActivePackage = await checkUserSubscription(currentUser.uid);
        
        if (!hasActivePackage) {
          navigate('/checkout'); // Redirect to checkout page if no active package
          return;
        }

        const userProgress = await loadUserProgress(currentUser.uid);
        setProgress(userProgress);
        
        await loadCategoryTotals(); // Load total questions per category
        const updatedCategories = await loadCategoryProgress(currentUser.uid);
        setCategories(updatedCategories);
      }
    });

    return () => unsubscribe();
  }, []);

  const checkUserSubscription = async (userId) => {
    // Query to check if the user has an active package
    const q = query(collection(db, 'subscriptions'), where('userId', '==', userId), where('isActive', '==', true));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty; // Returns true if there is an active package
  };

  const loadCategoryTotals = async () => {
    const updatedCategories = await Promise.all(categories.map(async (category) => {
      const q = query(collection(db, 'questions'), where('category', '==', category.name));
      const snapshot = await getCountFromServer(q);
      const totalQuestionsInCategory = snapshot.data().count;
      return {
        ...category,
        total: totalQuestionsInCategory,
      };
    }));

    setCategories(updatedCategories);

    // Update totalQuestions
    const total = updatedCategories.reduce((sum, category) => sum + category.total, 0);
    setTotalQuestions(total);
  };

  const loadUserProgress = async (userId) => {
    const q = query(collection(db, 'userProgress'), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);

    const answeredQuestions = {};
    let correctAnswers = 0;

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      answeredQuestions[data.questionId] = {
        selectedAnswer: data.selectedAnswer,
        isCorrect: data.isCorrect,
      };
      if (data.isCorrect) {
        correctAnswers += 1;
      }
    });

    return {
      totalAnswered: querySnapshot.size,
      correctAnswers: correctAnswers,
      answeredQuestions: answeredQuestions,
    };
  };

  const loadCategoryProgress = async (userId) => {
    const q = query(collection(db, 'userProgress'), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);

    const attemptedQuestionIds = querySnapshot.docs.map(doc => doc.data().questionId);

    const batchSize = 10;
    let categoryCounts = {};

    for (let i = 0; i < attemptedQuestionIds.length; i += batchSize) {
      const batchIds = attemptedQuestionIds.slice(i, i + batchSize);
      const questionsQuery = query(collection(db, 'questions'), where('__name__', 'in', batchIds));
      const questionsSnapshot = await getDocs(questionsQuery);
      questionsSnapshot.forEach(doc => {
        const data = doc.data();
        const category = data.category;
        if (categoryCounts[category]) {
          categoryCounts[category] += 1;
        } else {
          categoryCounts[category] = 1;
        }
      });
    }

    const updatedCategories = categories.map(category => {
      return {
        ...category,
        attempted: categoryCounts[category.name] || 0,
      };
    });

    return updatedCategories;
  };

  const handleCategorySelection = (categoryName) => {
    setSelectedCategories((prevSelected) => {
      if (prevSelected.includes(categoryName)) {
        return prevSelected.filter((name) => name !== categoryName);
      } else {
        return [...prevSelected, categoryName];
      }
    });
  };

  const handleSelectAll = () => {
    if (selectedCategories.length === categories.length) {
      setSelectedCategories([]);
    } else {
      setSelectedCategories(categories.map(category => category.name));
    }
  };

  const startQuestions = () => {
    if (selectedCategories.length === 0) {
      alert('Please select at least one category.');
      return;
    }
    navigate('/questions', { state: { selectedCategories } });
  };

  return (
    <div className="profile-page">
      <Progress progress={progress} />

      <div className="categories-section">
        <h2>CATEGORIES</h2>
        <div className="category-list">
          <div className="category-item all-category">
            <input
              type="checkbox"
              checked={selectedCategories.length === categories.length}
              onChange={handleSelectAll}
            />
            <span>All</span>
            <span className="category-attempted">Attempted {progress.totalAnswered} of {totalQuestions}</span>
          </div>
          {categories.map((category, index) => (
            <div key={index} className="category-item">
              <input
                type="checkbox"
                checked={selectedCategories.includes(category.name)}
                onChange={() => handleCategorySelection(category.name)}
                disabled={category.total === 0} // Disable categories with no questions
              />
              <span>{category.name}</span>
              <span className="category-attempted">Attempted {category.attempted} of {category.total}</span>
            </div>
          ))}
        </div>
        <button onClick={startQuestions}>Start Questions</button>
      </div>
      <Footer />
    </div>
  );
};

export default ProfilePage;
