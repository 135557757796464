import React, { useState, useEffect } from 'react';
import { auth } from '../firebase';
import { createUserWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './AuthForm.css';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to profile if already logged in
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate('/profile');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      setError(''); // Clear error on successful signup
      navigate('/checkout');
    } catch (err) {
      setError(err.message); // Display error if signup fails
    }
  };

  return (
    <div className="signin-container">
      <div className="signin-content">
        <div className="login-left">
          <h2>Sign Up</h2>
        </div>
        <div className="login-right">
          <form onSubmit={handleSignup}>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
            />
            <button type="submit">Sign Up</button>
          </form>
          {error && <p className="error-text">{error}</p>}
          <div className="signin-button-container">
            <p>Already a member? <span onClick={() => navigate('/login')} className="switch-form">Log In</span></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
