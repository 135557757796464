import React from 'react';
import './HeroSection.css';
import phoneImage from '../../images/phone.png';

const HeroSection = () => {
  return (
    <div className="send-money-container">
      <div className="text-section">
        <h1>
            10,000 Vetted <br />
          <span className="highlight">UKMLA style questions</span>
        </h1>
      </div>
      <div className="image-section">
        <img src={phoneImage} alt="Nuqoot App on Phone" className="phone-image" />
      </div>
    </div>
  );
};

export default HeroSection;