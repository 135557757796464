import React from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebase';
import './Checkout.css';

const Checkout = () => {
  const stripe = useStripe();

  const handleCheckout = async (packageType) => {
    const createCheckoutSession = httpsCallable(functions, 'createCheckoutSession');
    const { data } = await createCheckoutSession({ package: packageType });
    const result = await stripe.redirectToCheckout({ sessionId: data.id });

    if (result.error) {
      console.error(result.error.message);
    }
  };

  return (
    <div className="checkout-container">
      <div className="title">
        <h1>
          UKMLA AKT Question Banks
        </h1>
      </div>
      <h2>Select a Package</h2>
      <div className="packages">
        <div className="package-card">
          <h3>QBank 6 Months Access</h3>
          <p className="price">£35</p>
          <ul className="features">
            <li>Full QBank Access</li>
          </ul>
          <button className="btn" onClick={() => handleCheckout('6-month')}>Buy</button>
        </div>
        <div className="package-card">
          <h3>QBank 9 Months Access</h3>
          <p className="price">£40</p>
          <ul className="features">
            <li>Full QBank Access</li>
            <li>1 Self-Assessment*</li>
          </ul>
          <button className="btn" onClick={() => handleCheckout('9-month')}>Buy</button>
        </div>
        <div className="package-card">
          <h3>QBank 12 Months Access</h3>
          <p className="price">£45</p>
          <ul className="features">
            <li>Full QBank Access</li>
            <li>2 Self-Assessments*</li>
            <li>AI Study Assistant</li>
            <li>One-time Reset Option</li>
          </ul>
          <button className="btn" onClick={() => handleCheckout('12-month')}>Buy</button>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
