import React from 'react';

import EarlySignUp from '../HomePage/EarlySignUp';
import HeroSection from '../HomePage/HeroSection';
import Footer from '../Footer';
import MLAReplacement from '../HomePage/MLAreplacement';
import './HomePage.css';

const HomePage = () => {
  return (
    <div className="container">
    <HeroSection/>
    <MLAReplacement/>
    <Footer/>
    </div>
  );
};

export default HomePage;
