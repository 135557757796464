// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCN0wyeEIlQgo7QS6jFb-gwVC-aXvYeb5c",
  authDomain: "passmla.firebaseapp.com",
  projectId: "passmla",
  storageBucket: "passmla.appspot.com",
  messagingSenderId: "689620989055",
  appId: "1:689620989055:web:1a49e9d16130b91c9abb4a",
  measurementId: "G-RXP6R4PCHX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firebase Functions
const functions = getFunctions(app);

export { functions };
export const auth = getAuth(app);
export const db = getFirestore(app);