// src/App.js
import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import QuestionsPage from './components/Pages/QuestionsPage'; // The newly created page
import Progress from './components/Progress'; // If you want a separate progress page
import HomePage from './components/Pages/HomePage';
import ProfilePage from './components/Pages/ProfilePage.js';
import Login from './components/Login.js';
import Signup from './components/Signup.js';
import Checkout from './components/Auth/Checkout.js';
import Success from './components/Success';
import Cancel from './components/Cancel';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/questions" element={<QuestionsPage />} />
          <Route path="/progress" element={<Progress />} />
          <Route path="/" element={<HomePage/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/signup" element={<Signup/>}/>
          <Route path="/profile" element={<ProfilePage/>}/>
          <Route path="/checkout" element={<Checkout/>}/>
          <Route path="/success" element={<Success />} />
          <Route path="/cancel" element={<Cancel />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
